import _ from "lodash";
import {
  GET_LOCALSTORAGE,
  REMOVE_LOCALSTORAGE,
  SET_LOCALSTORAGE,
} from "./localStorage";

const setUserInfoToStorage = (data: any) => {
  if (!_.isEmpty(data)) {
    const userObj = {
      AUSR_MREC_Name: data?.AUSR_MREC_Name,
      AUSR_ACOU_NetUser: data?.AUSR_ACOU_NetUser,
      AUSR_ACOU_Identity: data?.AUSR_ACOU_Identity,
      AUSR_ACOU_MREC_Id: data?.AUSR_ACOU_MREC_Id,
      AUSR_Language: data?.AUSR_Language,
    };
    // last 15 min
    SET_LOCALSTORAGE(userObj, RMT_USER, 1000 * 60 * 15);
    return;
  } else {
    REMOVE_LOCALSTORAGE(RMT_USER);
    return undefined;
  }
};

const getUserInfoFromStorage = () => {
  const signInUser = GET_LOCALSTORAGE(RMT_USER);
  if (signInUser) {
    return signInUser;
  } else {
    return undefined;
  }
};

// key for user in localStorage
const RMT_USER = "RMT_USER";

export { setUserInfoToStorage, getUserInfoFromStorage, RMT_USER };
