import { initialState } from "../contexts/agreementContext";

export const agreementReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_INITIAL_DATA":
      return {
        ...state,
        docs: {
          ...action.payload.values.docs,
          update: state.docs.update ? false : true,
        },
        hist: [...action.payload.values.hist],
        INFO: [...action.payload.values.INFO],
        lastChangedWhen: {
          when: action.payload.values.lastChangedWhen.when,
          by: action.payload.values.lastChangedWhen.by,
        },
        isEditing: false,
        company: action.payload.values.company,
        contact: action.payload.values.contact,
        category: action.payload.values.category,
        agType: action.payload.values.agType,
        version: action.payload.values.version,
        workingVersion: action.payload.values.workingVersion,
        canChangeDocument: action.payload.values.canChangeDocument,
        historyTotalItems: action.payload.values.historyTotalItems,
        KHCL_HtmPathFile: action.payload.values.KHCL_HtmPathFile,
        KHCL_HtmPathFile2: action.payload.values.KHCL_HtmPathFile2,
        KHCL_HtmPathFile3: action.payload.values.KHCL_HtmPathFile3,
        KHCL_SrcPathFile: action.payload.values.KHCL_SrcPathFile,
      };

    case "UPDATE_GENERAL_DATA":
      return {
        ...state,
        hist: [...action.payload.values.hist],
        INFO: [...action.payload.values.INFO],
        lastChangedWhen: {
          when: action.payload.values.lastChangedWhen.when,
          by: action.payload.values.lastChangedWhen.by,
        },
        isEditing: false,
        company: action.payload.values.company,
        contact: action.payload.values.contact,
        category: action.payload.values.category,
        agType: action.payload.values.agType,
        version: action.payload.values.version,
        workingVersion: action.payload.values.workingVersion,
        canChangeDocument: action.payload.values.canChangeDocument,
        historyTotalItems: action.payload.values.historyTotalItems,
        KHCL_HtmPathFile: action.payload.values.KHCL_HtmPathFile,
        KHCL_HtmPathFile2: action.payload.values.KHCL_HtmPathFile2,
        KHCL_HtmPathFile3: action.payload.values.KHCL_HtmPathFile3,
        KHCL_SrcPathFile: action.payload.values.KHCL_SrcPathFile,
      };
    case "SET_NEW_AGREEMENT":
      return { ...state, docs: initialState.agreement.docs, isEditing: true };
    case "UPDATE_HISTLIST":
      return {
        ...state,
        hist: [...action.payload.values.hist],
        lastChangedWhen: {
          when: action.payload.values.lastChangedWhen.when,
          by: action.payload.values.lastChangedWhen.by,
        },
        historyTotalItems: action.payload.values.historyTotalItems,
      };

    case "UPDATE_INFOLIST":
      return {
        ...state,
        INFO: [...action.payload.values],
      };
    // RUNNING DEAL
    case "SET_RUNNINGDEALDATE":
      return { ...state, runningDealDate: action.payload.values };
    case "CLEAR_RUNNINGDEALDATE":
      return { ...state, runningDealDate: "" };
    // EDIT
    case "SET_IS_EDITING":
      return { ...state, isEditing: action.payload.values };
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload.values };
    // COMPANY
    case "SET_COMPANY":
      return { ...state, company: action.payload.values };
    case "REMOVE_COMPANY":
      return { ...state, company: null };
    // CONTACT
    case "SET_CONTACT":
      return { ...state, contact: action.payload.values };
    case "REMOVE_CONTACT":
      return { ...state, contact: null };
    // CONTACT
    case "SET_CATEGORY":
      return { ...state, category: action.payload.values };
    case "REMOVE_CATEGORY":
      return { ...state, category: null };
    // TYPE
    case "SET_AGTYPE":
      return { ...state, agType: action.payload.values };
    case "REMOVE_AGTYPE":
      return { ...state, agType: null };
    // IFRAME SRC
    case "SET_IFRAME_SRC":
      return { ...state, iframeSRC: action.payload.values };
    case "REMOVE_IFRAME_SRC":
      return { ...state, iframeSRC: null };

    case "SET_DOCUMENT":
      return {
        ...state,
        docs: {
          ...action.payload.values,
          update: state.docs.update ? false : true,
        },
      };

    case "REMOVE_DOCUMENT":
      return {
        ...state,
        docs: initialState.agreement.docs,
        ...action.payload.values,
      };

    case "DOCUMENT_DONE_EDITING":
      return {
        ...state,
        isEditing: false,
        isLoading: false,
        ...action.payload.values,
      };

    case "UPDATE_HISTORY_COUNT":
      return { ...state, historyTotalItems: action.payload.values };

    case "UPDATE_SHARED_DATA":
      return {
        ...state,
        isLoading: false,
        version: action.payload.values.version,
        workingVersion: action.payload.values.workingVersion,
        canChangeDocument: action.payload.values.canChangeDocument,
        historyTotalItems: action.payload.values.historyTotalItems,
        KHCL_HtmPathFile: action.payload.values.KHCL_HtmPathFile,
        KHCL_HtmPathFile2: action.payload.values.KHCL_HtmPathFile2,
        KHCL_HtmPathFile3: action.payload.values.KHCL_HtmPathFile3,
        KHCL_SrcPathFile: action.payload.values.KHCL_SrcPathFile,
        actionNr: initialState.agreement.actionNr,
        docs: {
          ...state.docs,
          ...action.payload.values.docs,
          update: !state.docs.update
        },
        INFO: action.payload.values.INFO
      };
    // ALL
    case "CLEAR_ALL":
      return { ...initialState.agreement, isEditing: true };

    case "CLEAR_DOC_DATA":
      return {
        ...initialState.agreement,
        actionNr: state.actionNr,
      };

    case "UPDATE_ACTION_REQUIRED":
      return {
        ...state,
        actionNr: action.payload.values.actionNr,
      };

    default:
      return state;
  }
};
