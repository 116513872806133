import { useEffect, useContext, useState, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { UserContext } from "../../store/contexts/userContext";
import ConsoleLog from "./log/logErrror";
import useApiHook from "./useApiHook";

// OKTA token
import { useOktaAuth } from "@okta/okta-react";
import config from "../../config";
import {
  getUserInfoFromStorage,
  setUserInfoToStorage,
} from "./constants";
import { InitialError } from "../favorites_dnd/helpers";
import ModalContent from "./modals/ModalContent";
import Modal from "./modals/modal";
import { closeModal, openModal } from "./modals/modalFunctions";
import InputErrors from "./errorHandling/InputErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HiddenHeader = () => {
  const authState = useOktaAuth()?.authState;
  const { t, i18n } = useTranslation();
  const { GET_API_HOOK } = useApiHook();
  const { user, userDispatch } = useContext(UserContext);
  const [tryAuthenticate, setTryAuthenticate] = useState(true);
  const [counter, setCounter] = useState(1);
  const [isError, setIsError] = useState(InitialError);

  const modalRef = useRef(null as any);

  // set user to context and change to correct lang if not set.
  const getRmtUser = async () => {
    setCounter((prevState: any) => prevState + 1);
    const USER_INFO = getUserInfoFromStorage();
    if (USER_INFO?.AUSR_Language) {
      userDispatch({
        type: "SET_USER",
        payload: { values: USER_INFO },
      });
      i18n.changeLanguage(USER_INFO.AUSR_Language);
    } else {
      try {
        const userDetails: any = await GET_API_HOOK(`/users?id=0`);
        if (userDetails?.status === 200) {
          setUserInfoToStorage(userDetails.data[0]);
          userDispatch({
            type: "SET_USER",
            payload: { values: userDetails.data[0] },
          });
          i18n.changeLanguage(userDetails?.data[0]?.AUSR_Language);
        } else {
          // Cors errors dont give a response
          if (userDetails?.response === undefined) {
            setIsError({
              errorMessage: `${t("Error.InternalError")}, ${t(
                "Error.UserError"
              )} (${userDetails?.message})`,
              errorStatus: 500,
            });
            return;
            // API missing?
          } else if (userDetails?.response?.status === 404) {
            setIsError({
              errorMessage: `${t("Error.InternalError")}, ${t(
                "Error.UserError"
              )} (${userDetails?.message})`,
              errorStatus: 500,
            });
            return;
          }
          setTryAuthenticate((prevState) => (prevState ? false : true));
        }
      } catch (err) {
        ConsoleLog(err);
      }
    }
  };

  useEffect(() => {
    if (user?.signInUser === null) {
      if (
        config.use_okta_authentication &&
        authState?.accessToken?.accessToken &&
        counter === 1
      ) {
        getRmtUser();
      } else if (!config.use_okta_authentication && counter === 1) {
        getRmtUser();
      } else if (
        config.use_okta_authentication &&
        authState?.accessToken?.accessToken &&
        counter <= 5
      ) {
        setTimeout(() => {
          getRmtUser();
        }, 500);
      } else if (!config.use_okta_authentication && counter <= 5) {
        setTimeout(() => {
          getRmtUser();
        }, 500);
      } else if (!config.use_okta_authentication && counter <= 20) {
        setTimeout(() => {
          getRmtUser();
        }, 5000);
      } else {
        console.error(
          "Could not get sign in user credentials, language might be affected"
        );
        setTimeout(() => {
          setTryAuthenticate((prevState) => (prevState ? false : true));
        }, 10000);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.accessToken?.accessToken, tryAuthenticate, user?.signInUser]);

  const onRefChange = useCallback(
    (node) => {
      if (node === null) {
        // DOM node referenced by ref has been unmounted
        return null;
      } else {
        if (isError.errorMessage) openModal(modalRef);
        return node;
      }
    },
    [isError.errorMessage]
  );

  const handleCloseModal = () => {
    setIsError(InitialError);
    closeModal(modalRef);
  };

  return (
    <div ref={onRefChange} className="topbar">
      <Modal ref={modalRef}>
        <ModalContent
          css="px-2"
          modalRef={modalRef}
          title={t("Error.Header")}
          closeModalCustomFunc={handleCloseModal}
          icon={
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              className="text-warning mr-2"
            />
          }
        >
          <section>
            <InputErrors text={isError.errorMessage} />
            <div className="d-flex justify-content-center mt-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleCloseModal()}
              >
                {t("Button.Close")}
              </button>
            </div>
          </section>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default HiddenHeader;
