import React from "react";

import { library as Library } from "@fortawesome/fontawesome-svg-core";
import {
  faEye,
  faFilter,
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faSearch,
  faClipboardList,
  faEllipsisV,
  faExclamationTriangle,
  faUserPlus,
  faCheck,
  faEdit,
  faTasks,
  faInfoCircle,
  faTimes,
  faPlus,
  faChevronRight,
  faChevronLeft,
  faPencilAlt,
  faStar as fasStar,
  faFileDownload,
  faUndo,
  faLink,
  faEnvelope,
  faArrowsAltH,
  faDownload,
  faList,
  faExpand,
  faCompress,
  faBars,
  faArchive,
  faSyncAlt,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faSignOutAlt,
  faQuestionCircle,
  faHome,
  faHistory,
  faBoxOpen,
  faUndoAlt,
  faFileSignature,
  faSitemap,
  faChartPie,
  faCog,
  faFileContract,
  faFile,
  faGripVertical,
  faTools,
  faFont,
  faHeading,
  faTextHeight,
  faTag,
  faSortNumericDown,
  faColumns,
  faEllipsisH,
  faFolderPlus,
  faFolder,
  faUser,
  faCamera,
  faToggleOff,
  faQrcode,
  faToggleOn,
  faUpload,
  faFileUpload,
  faExternalLink,
  faLock,
  faTimesCircle,
  faMapMarkerAlt,
  faTree,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import {
  faListAlt,
  faCheckCircle,
  faBell,
  faTrashAlt,
  faStar,
  faCommentAlt,
  faArrowAltCircleLeft,
  faSave,
  faFileAlt,
  faCheckSquare,
  faSquare,
  faPaperPlane,
  faCalendarAlt,
  faHandshake,
  faEye as farEye,
  faCaretSquareDown,
  faClock,
  faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";

Library.add(
  faEye,
  faFilter,
  faArrowDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faArrowUp,
  faChevronDown,
  faSearch,
  faClipboardList,
  faEllipsisV,
  faListAlt,
  faExclamationTriangle,
  faCheckCircle,
  faBell,
  faUserPlus,
  faCheck,
  faTrashAlt,
  faEdit,
  faStar,
  faTasks,
  faInfoCircle,
  faCommentAlt,
  faTimes,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faPlus,
  faSave,
  faPencilAlt,
  faStar,
  fasStar,
  faFileDownload,
  faFileAlt,
  faChevronDown,
  faCheckSquare,
  faSquare,
  faPaperPlane,
  faUndo,
  faLink,
  faEnvelope,
  faArrowsAltH,
  faDownload,
  faCalendarAlt,
  faList,
  faFile,
  faExpand,
  faCompress,
  faBars,
  faArchive,
  faSyncAlt,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faSignOutAlt,
  faQuestionCircle,
  faHome,
  faHistory,
  faBoxOpen,
  faUndoAlt,
  faFileSignature,
  faSitemap,
  faChartPie,
  faCog,
  faListAlt,
  faFileContract,
  faFile,
  faHandshake,
  faGripVertical,
  farEye,
  faTools,
  faFont,
  faHeading,
  faTextHeight,
  faCaretSquareDown,
  faTag,
  faSortNumericDown,
  faColumns,
  faPaperPlane,
  faEllipsisH,
  faArrowsAltH,
  faFolderPlus,
  faFolder,
  faClock,
  faEllipsisV,
  faUser,
  faCamera,
  faToggleOff,
  faQrcode,
  faToggleOn,
  faUpload,
  faFileUpload,
  faExternalLink,
  faLock,
  faTimesCircle,
  faMapMarkerAlt,
  faTree,
  faUsers
);

// BOOTSTAP ICONS
interface SVGProps {
  color?: string;
}

export const BoDoubleDown = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-chevron-double-down"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
    />
    <path
      fillRule="evenodd"
      d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
    />
  </svg>
);

export const BoDoubleUp = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-chevron-double-up"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"
    />
    <path
      fillRule="evenodd"
      d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
    />
  </svg>
);

export const InfoCircle = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-info-circle"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
    />
    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
    <circle cx="8" cy="4.5" r="1" />
  </svg>
);

export const ExclamationTriangle = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-exclamation-circle"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
    />
    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
  </svg>
);

export const BiPublishedDoc = (props: any, { color }: SVGProps) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 16 16"
    className="bi bi-file-earmark-check mr-1"
    fill={color ? color : "currentColor"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
    <path
      fillRule="evenodd"
      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
    />
  </svg>
);

export const BiNonPublichedDoc = (props: any, { color }: SVGProps) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 16 16"
    className="bi bi-file-earmark text-muted mr-1"
    fill={color ? color : "currentColor"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
  </svg>
);

export const FileDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1rem"
    height="1rem"
    fill="currentColor"
    className="bi bi-file-earmark-arrow-down-fill"
    viewBox="0 0 16 16"
  >
    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
  </svg>
);

export const BiFunnel = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-funnel"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
    />
  </svg>
);

export const BiChevronUp = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-chevron-up ml-2"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
    />
  </svg>
);

export const BiChevronDown = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-chevron-down ml-2"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
    />
  </svg>
);

export const BiNumbers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-123"
    viewBox="0 0 16 16"
  >
    <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z" />
  </svg>
);

export const BiTextArea = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-textarea-t"
    viewBox="0 0 16 16"
  >
    <path d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874V2.5zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5v3.563zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
    <path d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386L11.434 4z" />
  </svg>
);
