import React from "react";

interface InputErrorProps {
  text: string | null;
  css?: string;
}

const InputErrors = ({ text, css }: InputErrorProps) => {
  return (
    <div>
      <p className={`text-danger m-0 ${css}`}>{text}</p>
    </div>
  );
};

export default InputErrors;
