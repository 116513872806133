import config from "../../config";
import { apiVersion } from "../azsearch/helperVariables";

const customApiUrl = config.use_custom_api_url
  ? config.use_custom_api_url
  : window.location.origin;
const customOriginUrl = config.use_custom_origin_url;

const ApiURL = () => {
  let BASE_URL: any;
  if (process.env.NODE_ENV === "production") {
    BASE_URL = customApiUrl + apiVersion;
    return BASE_URL;
  } else if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    BASE_URL = customApiUrl + apiVersion;
    return BASE_URL;
  } else {
    BASE_URL = window.location.origin;
    return BASE_URL;
  }
};

const OriginURL = () => {
  let BASE_URL: any;

  if (process.env.NODE_ENV === "production") {
    BASE_URL = window.location.origin;
    return BASE_URL;
  } else if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    BASE_URL = customOriginUrl;
    return BASE_URL;
  } else {
    BASE_URL = window.location.origin;
    return BASE_URL;
  }
};

const CheckIfProduction = () => {
  let isProduction: boolean;

  if (process.env.NODE_ENV === "production") {
    isProduction = true;
    return isProduction;
  } else if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    isProduction = false;
    return isProduction;
  } else {
    isProduction = true;
    return isProduction;
  }
};

export { ApiURL, OriginURL, CheckIfProduction };
