// eslint-disable-next-line
declare global {
  // eslint-disable-next-line
  interface Window {
    RmtConfig: {
      search_task_id: string;
      task_id_list: string;
      use_tags_multi_filter: string;
      use_full_search: string;
      search_param_character: string;
      always_show_natural_id: string;
      person_filter_relations: string;
      person_document_manager: string;
      scoring_profile: string;
      use_direct_hit_suggestion: string;
      use_mrec_name_suggestion: string;
      use_datetime_format: string;
      azure_client_id: string;
      azure_scope: string;
      display_info_button_search: string;
      use_custom_api_url: string;
      use_custom_origin_url: string;
      display_advanced_filters: string;
      display_search_card_status_badge: string;
      switch_search_card_links: string;
      use_favlist_v2: string;
      use_todolist_v2: string;
      use_pwa_layout: string;
      use_blob_storage: string;
      use_react_pdf_printer: string;
      skandion_route_to_ViewDoc_if_folder: string;
      skandion_agreement_attachments: string;
      use_okta_authentication: string;
      okta_client_id: string;
      okta_domain: string;
      use_local_authentication: string;
      display_todolist_send_reminder: string;
    };
    RmtGlobal: {
      mrec_id: string;
    };
  }
}

const config = window.RmtConfig;

export default config;
