export const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, ...action.payload.values };
    case "UPDATE_USER":
      return { ...state, ...action.payload.values };
    default:
      return state;
  }
};
