import React, { forwardRef, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  closeOnOutsideClick?: boolean;
  noOverlayBackground?: boolean;
  openInFullscreen?: boolean;
  children: React.ReactElement;
  ref: any;
}

const Modal = forwardRef((props: ModalProps, ref) => {
  const {
    closeOnOutsideClick,
    noOverlayBackground,
    openInFullscreen,
  }: ModalProps = props;
  const [display, setDisplay] = useState(false);
  const [fullScreen, setFullScreen] = useState(
    openInFullscreen ? openInFullscreen : false
  );

  useImperativeHandle(ref, () => {
    return {
      openModal: () => open(),
      close: () => close(),
      fullScreenModal: () => changeFullScreen(),
      fullScreenState: fullScreen,
      modalIsOpen: display
    };
  });

  const open = () => {
    setDisplay(true);
  };

  const close = () => {
    setDisplay(false);
    setFullScreen(openInFullscreen ? true : false);
  };

  const changeFullScreen = () => {
    setFullScreen((prevState) => (prevState ? false : true));
  };

  if (display) {
    return ReactDOM.createPortal(
      <div
        id="modal"
        className={"modal-wrapper"}
        style={{ pointerEvents: noOverlayBackground ? "none" : "auto" }}
      >
        <div
          onClick={() => (closeOnOutsideClick === false ? "" : close())}
          className={"modal-backdrop"}
          style={{
            pointerEvents: noOverlayBackground ? "none" : "auto",
            backgroundColor: noOverlayBackground
              ? "transparent"
              : "rgba(0, 0, 0, 0.3)",
          }}
        />
        <div
          className={"modal-box border"}
          style={{
            width: fullScreen ? "100vw" : "",
            height: fullScreen ? "100vh" : "",
            maxWidth: fullScreen ? "98vw" : "",
            maxHeight: fullScreen ? "98vh" : "",
            pointerEvents: "auto",
            overflow: noOverlayBackground ? "auto" : "visable",
          }}
        >
          {props.children}
        </div>
      </div>,
      //@ts-ignore
      document.getElementById("modal-root")
    );
  }

  return null;
});

export default Modal;
