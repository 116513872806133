import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import axios from "axios";
import Backend from "i18next-http-backend";

import { CheckIfProduction, OriginURL } from "../components/utils/checkUrl";
import ConsoleLog from "../components/utils/log/logErrror";

import { setUserInfoToStorage, getUserInfoFromStorage } from "../components/utils/constants";
import config from "../config";

const getPathToLocales = () => {
  let BASE_URL: any;
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    BASE_URL = "";
    return BASE_URL;
  } else {
    const url = window.location.pathname;
    BASE_URL = url.substring(0, url.length - 11);
    return BASE_URL;
  }
};

const Languages = ["en", "sv"];

const translateToLang = (lang: string) => {
  switch (lang) {
    case "en-GB":
      return Languages[0];
    case "nn":
      return Languages[0];
    case "sv":
      return Languages[1];
    default:
      return "sv";
  }
};

const lang = config?.use_datetime_format?.length
  ? translateToLang(config.use_datetime_format)
  : "sv";

const options = {
  fallbackLng: lang,
  preload: Languages,
  debug: false, //display errors in console
  backend: {
    loadPath: `${getPathToLocales()}/locales/{{lng}}/{{ns}}.json`,
  },
  whitelist: Languages,
};

const isProduction = CheckIfProduction();

const languageDetect: any = {
  type: "languageDetector",
  async: true, // flags below detection to be async
  detect:
    isProduction && !config?.use_blob_storage
      ? async (callback: any) => {
          const USER_INFO = getUserInfoFromStorage();
          if (USER_INFO?.AUSR_Language) {
            callback(USER_INFO.AUSR_Language);
          } else {
            try {
              const response = await axios({
                method: "GET",
                withCredentials: true,
                url: `${OriginURL()}/v1/api/users?id=0`,
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              });
              if (response?.status === 200) {
                setUserInfoToStorage(response.data[0]);
                callback(
                  response?.data[0]?.AUSR_Language
                    ? response?.data[0]?.AUSR_Language
                    : lang
                );
              } else {
                callback(lang);
              }
            } catch (err) {
              ConsoleLog(err);
              callback(lang);
            }
          }
        }
      : (callback: any) => callback(config?.use_blob_storage ? lang : "sv"),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n.use(Backend).use(languageDetect).use(initReactI18next).init(options);
export default i18n;
