import config from "../../config";

const fuzzy = config.use_full_search;
const scoringProfile = config.scoring_profile;


const searchParams = {
  scoringProfile: `${scoringProfile ? scoringProfile : ""}`,
  searchMode: "all",
  highlight: "MREC_Memo, MREC_Name, CATE_Name, MREC_NaturalId, content",
  queryType: fuzzy ? "full" : "simple",
  select: `MREC_Id, IconPath, CATE_Name, MREC_Name, MREC_Memo, MREC_NaturalId, MREC_DateTime, ORGN_Name_Owner, Person, Author, Approver, Reviewer, STAT_Shortname, path${
    config.search_task_id && !config.use_tags_multi_filter ? ", DOCS_TaskId" : ""
  }${config.person_document_manager ? ", DocumentManager" : ""} ${config.display_advanced_filters ? ", Reference" : ""} ${config.use_tags_multi_filter ? ", Tags" : ""}`,
};

const apiVersion = "/v2/api";

export { searchParams, apiVersion };
