import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeFullScreenState } from "../modals/modalFunctions";

interface ModalFullscreenbtnProps {
  modalRef: any;
  isDisabled?: boolean;
}

const ModalFullscreenbtn = ({
  modalRef,
  isDisabled,
}: ModalFullscreenbtnProps) => {
  const [size, setSize] = useState(
    modalRef?.current?.fullScreenState
      ? !modalRef?.current?.fullScreenState
      : true
  );

  const changeScreenState = () => {
    setSize((prevState: any) => (prevState ? false : true));
    changeFullScreenState(modalRef);
  };

  return (
    <button
      title={size ? "Fullscreen" : "Compress"}
      className="btn btn-sm btn-link btn-sm position-absolute main-text-color"
      type="button"
      disabled={isDisabled}
      onClick={() => changeScreenState()}
      style={{ top: "0", right: "30px" }}
    >
      {size ? (
        <FontAwesomeIcon icon={["fas", "expand"]} />
      ) : (
        <FontAwesomeIcon icon={["fas", "compress"]} />
      )}
    </button>
  );
};

export default ModalFullscreenbtn;