import React, { createContext, useReducer } from "react";

import { agreementReducer } from "../reducers/agreementReducers";

export type Company = {
  OrderNo: string;
  Rel_Id: string;
  label: string;
  labelId: string;
  value: string;
};

type Contact = {
  OrderNo: string;
  Rel_Id: string;
  label: string;
  labelId: string;
  value: string;
};

type Category = {
  OrderNo: string;
  Rel_Id: string;
  label: string;
  labelId: string;
  value: string;
};

type AgType = {
  OrderNo: string;
  Rel_Id: string;
  label: string;
  labelId: string;
  value: string;
};

type HIST = {
  HIST_DateTime: Date;
  HIST_EventType: string;
  HIST_MREC_Id: number;
  HIST_PathFile: string;
  HIST_RoleId: number;
  HIST_Row: number;
  HIST_Sign: string;
  HIST_Text: string;
  HIST_Version: string;
};

export type DocumentProps = {
  url: string | null;
  title: string | null;
  isNew: boolean | null;
  update: boolean;
};

type INFO = {
  INFO_AUSR_MREC_Id: number;
  INFO_AUSR_MREC_Name: string;
  INFO_DateTime: string;
  INFO_MREC_Id: number;
  INFO_ORGU_MREC_Id: number;
  INFO_PathFile: string;
  INFO_PathFileName: string;
  INFO_Row: number;
  INFO_Sign: string;
  INFO_StepId: number;
  INFO_StepName: string;
  INFO_Text: string;
};

type InitialStateType = {
  agreement: {
    runningDealDate: string;
    iframeSRC: string | null;
    isEditing: boolean;
    isLoading: boolean;
    company: Company | null;
    contact: Contact | null;
    category: Category | null;
    agType: AgType | null;
    docs: DocumentProps;
    hist: HIST[];
    INFO: INFO[];
    lastChangedWhen: {
      when: string;
      by: string;
    };
    version: string;
    workingVersion: string | null;
    canChangeDocument: boolean;
    historyTotalItems: number;
    KHCL_HtmPathFile: string;
    KHCL_HtmPathFile2: string;
    KHCL_HtmPathFile3: string;
    KHCL_SrcPathFile: string;
    actionNr: { action: number | null; id: string };
  };
};

export const initialState = {
  agreement: {
    runningDealDate: "",
    iframeSRC: "",
    isEditing: false,
    isLoading: false,
    company: null,
    contact: null,
    category: null,
    agType: null,
    docs: {
      url: null,
      title: null,
      isNew: null,
      update: false,
    },
    hist: [],
    INFO: [],
    lastChangedWhen: {
      when: "",
      by: "",
    },
    version: "0",
    workingVersion: null,
    canChangeDocument: true,
    historyTotalItems: 0,
    KHCL_HtmPathFile: "",
    KHCL_HtmPathFile2: "",
    KHCL_HtmPathFile3: "",
    KHCL_SrcPathFile: "",
    actionNr: { action: null, id: "" },
  },
};

const AgreementContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ agreement }: InitialStateType, action: any) => ({
  agreement: agreementReducer(agreement, action),
});

const AgreementProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AgreementContext.Provider value={{ state, dispatch }}>
      {children}
    </AgreementContext.Provider>
  );
};

export { AgreementContext, AgreementProvider };
