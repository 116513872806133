import React, { createContext, useReducer } from "react";

import { userReducer } from "../reducers/userReducer";

export type signInUserType = {
  AUSR_ACOU_Email: string;
  AUSR_ACOU_Identity: string;
  AUSR_ACOU_MREC_Id: number;
  AUSR_ACOU_NetUser: string;
  AUSR_Language: string;
  AUSR_MREC_Name: string;
  ORGN_Name_Owner: string;
  ORGN_NaturalId_Owner: string;
  Roles?: any[];
};

type InitialStateType = {
  signInUser: signInUserType | null;
};

export const initialState = {
  signInUser: null,
};

const UserContext = createContext<{
  user: InitialStateType;
  userDispatch: React.Dispatch<any>;
}>({
  user: initialState,
  userDispatch: () => null,
});

const mainReducer = ({ signInUser }: InitialStateType, action: any) => ({
  signInUser: userReducer(signInUser, action),
});

const UserProvider: React.FC = ({ children }) => {
  const [user, userDispatch] = useReducer(mainReducer, initialState);

  return (
    <UserContext.Provider value={{ user, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
