import React from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { modalHeading } from "../../colors";
import CloseModalbtn from "../buttons/CloseModalbtn";
import ModalFullscreenbtn from "../buttons/ModalFullscreenbtn";

interface ModalContentProps {
  modalRef?: any;
  children: React.ReactElement;
  title: string;
  titleProperty?: string;
  icon?: React.ReactElement;
  css?: string;
  closeModalCustomFunc?: () => void;
  actionButtons?: React.ReactElement;
  fullScreenButton?: boolean;
  hideTitle?: boolean;
}

const ModalContent = ({
  modalRef,
  children,
  title,
  titleProperty,
  icon,
  css,
  closeModalCustomFunc,
  actionButtons,
  fullScreenButton,
  hideTitle,
}: ModalContentProps) => {
  return (
    <div className="d-flex flex-column bg-white rounded position-relative w-100 h-100">
      {modalRef && (
        <CloseModalbtn
          modalRef={modalRef}
          closeModalCustomFunc={closeModalCustomFunc}
        />
      )}
      {modalRef && fullScreenButton && (
        <ModalFullscreenbtn modalRef={modalRef} />
      )}
      <section className="py-md-2 px-md-3 px-1 py-1 d-flex flex-column w-100 h-100">
        <h4
          className={`${modalHeading} ${css ? " " + css : ""}`}
          title={titleProperty ? titleProperty : ""}
        >
          {icon ? icon : ""}
          {title?.length ? title : !hideTitle ? <Skeleton width={200} /> : ""}
        </h4>
        {children}
        {actionButtons ? (
          <div className="mt-2 d-flex justify-content-center w-100">
            {actionButtons}
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default ModalContent;
