import React from "react";

import { useTranslation } from "react-i18next";

interface LoadingSpinnerProps {
  width?: string | number;
  height?: string | number;
  css?: string;
}

const LoadingSpinner = ({ width, height, css }: LoadingSpinnerProps) => (
  <div
    className={`d-flex justify-content-center ${css ? css : "pt-4"}`}
  >
    <div
      className="spinner-border"
      role="status"
      style={{ width: width, height: height }}
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

interface SavingIndicatorPrpps {
  text?: string;
  css?: string;
  overlay?: string;
}

const SavingIndicator = ({ text, css, overlay }: SavingIndicatorPrpps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`position-absolute d-flex align-items-center justify-content-center h-100 w-100 ${
        css ? css : ""
      }`}
      style={{
        zIndex: 100,
        backgroundColor: overlay ? overlay : "",
        top: 0,
        right: 0,
      }}
    >
      <div>
        {text ? text : t("General.Saves")}
        <LoadingSpinner />
      </div>
    </div>
  );
};

export { LoadingSpinner, SavingIndicator };
