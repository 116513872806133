import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeModal } from "../modals/modalFunctions";

interface CloseModalProps {
  modalRef: any;
  isDisabled?: boolean;
  closeModalCustomFunc?: () => void;
}

const CloseModalbtn = ({
  modalRef,
  isDisabled,
  closeModalCustomFunc,
}: CloseModalProps) => {
  return (
    <button
      className="btn btn-link btn-sm position-absolute py-1 px-2 main-text-color"
      type="button"
      disabled={isDisabled}
      onClick={() =>
        closeModalCustomFunc ? closeModalCustomFunc() : closeModal(modalRef)
      }
      style={{ top: "0", right: "0" }}
    >
      <FontAwesomeIcon icon={["fas", "times"]} />
    </button>
  );
};

export default CloseModalbtn;
