import { FavChildObject, FavParentObject } from "./types/DndFavListTypes";

export const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

export const removeItem = (list: any, path: string) => {
  const splitItemPath = path.split("-");
  // remove item from rows
  if (splitItemPath.length === 1) {
    const newList = Array.from(list);
    return removeChildFromChildren(newList, splitItemPath);
  }
  // remove a item from a folder
  else if (splitItemPath.length === 2) {
    const newList = Array.from(list);

    return removeChildFromChildren(newList, splitItemPath);
  }
};

export const remove = (arr: any, index: any) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1),
];

export const removeChildFromChildren = (
  children: any,
  splitItemPath: string[]
) => {
  if (splitItemPath.length === 1) {
    const itemIndex = Number(splitItemPath[0]);
    return remove(children, itemIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitItemPath.slice(0, 1));

  // Update the specific node's children
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: removeChildFromChildren(
      nodeChildren.children,
      splitItemChildrenPath
    ).map((child, index) => ({
      ...child,
      sortValue: index,
    })),
  };

  return updatedChildren;
};

const updateSortValuesChildren = (
  list?: FavChildObject[],
  sortValueBump?: number
) => {
  if (list?.length) {
    const result = new Array(list.length);
    for (let i = 0; i < list.length; ++i) {
      result[i] = {
        ...list[i],
        sortValue: sortValueBump ? i + sortValueBump : i,
      };
    }
    return result;
  } else return [];
};

export const updateSortValues = (
  list: FavParentObject[],
  onlyParent?: boolean,
  sortValueBump?: number
) => {
  const result = new Array(list.length);

  for (let i = 0; i < list.length; ++i) {
    if (list[i].children && !onlyParent) {
      result[i] = {
        ...list[i],
        children:
          list?.[i]?.children && updateSortValuesChildren(list[i].children),
        sortValue: sortValueBump ? i + sortValueBump : i,
      };
    } else {
      result[i] = {
        ...list[i],
        sortValue: sortValueBump ? i + sortValueBump : i,
      };
    }
  }

  return result;
};

export const InitialError: InitialErrorType = {
  errorMessage: "",
  errorStatus: 0,
  displayError: false
};

export type InitialErrorType = {
  errorMessage: string;
  errorStatus: number;
  displayError?: boolean;
};
