import config from "./config";

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
      clientId: `${config?.azure_client_id}`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };
  
  // Add here scopes for id token to be used at MS Identity Platform endpoints.
  export const loginRequest = {
    scopes: [`${config?.azure_scope}`],
  };
  
  // use sid or loginHint for accessing silent
  export const loginRequestSilent = {
    scopes: [`${config?.azure_scope}`],
  };
  
  // Add here the endpoints for MS Graph API services you would like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  };
  
