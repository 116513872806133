import React, { lazy } from "react";
// eslint-disable-next-line
import { HashRouter as Switch, Route } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

import { UserProvider } from "../store/contexts/userContext";
import { AgreementProvider } from "../store/contexts/agreementContext";
import HiddenHeader from "../components/utils/HiddenHeader";
import config from "../config";

const MobileRoutes = lazy(() => import("./MobileRoutes"));

const Home = lazy(() => import("../components/Home"));
const AzSearch = lazy(() => import("../components/azsearch"));
const TodoPage = lazy(() => import("../components/todolist"));
const Favorites = lazy(() => import("../components/favorites"));
// Favorites v2 drag n drop
// Favorites with react-dnd
const Favorites_dnd = lazy(
  () => import("../components/favorites_dnd/Favlist_dnd")
);

const HistoryList = lazy(
  () => import("../components/history/pages/HistoryPage")
);
const NoMatch = lazy(() => import("../components/noMatch/NoMatch"));
// AGREEMENT ROUTES
const Agreements = lazy(
  () => import("../components/agreements/pages/agreementList")
);
const DynamicRoute = lazy(
  () => import("../components/agreements/pages/dynamicRoute")
);
const CreateAgreementsPage = lazy(
  () => import("../components/agreements/pages/createAgreementPage")
);
/////////////////////////////////////
// DYNAMIC FORMS ROUTES CORVUS CUSTOM
const DynamicForm = lazy(
  () => import("../components/corvusform/pages/DynamicFormPage")
);
const NewFormPage = lazy(
  () => import("../components/corvusform/pages/NewFormPage")
);
const CorvusSelectFormPage = lazy(
  () => import("../components/corvusform/pages/CorvusSelectForm")
);
const CorvusEditableList = lazy(
  () => import("../components/corvusform/pages/CorvusEditableList")
);
//////////////////////
// DYNAMIC FORM ROUTES
const DynamicCreateNewForm = lazy(
  () => import("../components/dynamicForm/pages/DynamicCreateNewForm")
);
const DynamicFormPage = lazy(
  () => import("../components/dynamicForm/pages/DynamicFormPage")
);
const DynamicProperties = lazy(
  () => import("../components/properties/pages/Properties")
);
const DynamicList = lazy(
  () => import("../components/agGridList/pages/DynamicList")
);
const CorvusIframe = lazy(
  () => import("../components/corvusform/pages/CorvusIframe")
);

//////////////////////
// QLINEA DEMO FORM
const QlineaDemoForm = lazy(
  () => import("../components/qlinea/QlineaDemoForm")
);

// Test
const DynamicEditableLinks = lazy(
  () =>
    import(
      "../components/dynamicForm/form/inputs/dynamicEditableLinks/DynamicEditableLinks"
    )
);

// Test with rechart
const ReCharts = lazy(() => import("../components/reCharts/ReCharts"));
const AgreementCharts = lazy(
  () => import("../components/admin/charts/AgreementCharts")
);
const DocumentCharts = lazy(
  () => import("../components/admin/charts/DocumentCharts")
);
const YearChart = lazy(() => import("../components/reCharts/year/YearChart"));

const SupportCharts = lazy(
  () => import("../components/admin/charts/SupportCharts")
);

// Field page to update and view form definition.
const Fields = lazy(() => import("../components/admin/fields/Fields"));
const FormBuilderPage = lazy(
  () => import("../components/formbuilder/pages/FormBuilderPage")
);
const FbDynamicFormPage = lazy(
  () => import("../components/formbuilder/pages/FbDynamicFormPage")
);

const AgList = lazy(() => import("../components/agGridList/pages/AgList"));
const Email = lazy(() => import("../components/email/pages/Email"));
const FullWindow = lazy(() => import("../components/layout/FullWindow"));

const MainRoutes = () => {
  return (
    <>
      <HiddenHeader />
      <Route exact path="/" component={Home} />
      <Route exact path="/search" component={AzSearch} />
      <Route
        exact
        path={"/favorites"}
        component={config.use_favlist_v2 ? Favorites_dnd : Favorites}
      />
      {/* Favorites v2 drag n drop */}
      <Route exact path="/favorites_react-dnd" component={Favorites_dnd} />

      <Route exact path="/todolist" component={TodoPage} />
      <Route exact path="/histlist/:id" component={HistoryList} />
      <Route exact path="/properties/:id" component={DynamicProperties} />
      <Route exact path="/form-splitter/:id" component={DynamicFormPage} />
      <Route exact path="/create-form/:id" component={DynamicCreateNewForm} />

      <Route exact path="/list/:id" component={DynamicList} />
      <Route exact path="/email/:id" component={Email} />

      {/* Corvus Routes for NCR/CAR */}
      <Route exact path="/ncr/:id" component={DynamicForm} />
      <Route exact path="/ncr/:id/:prop/:action" component={DynamicForm} />
      <Route exact path="/newform/:id" component={NewFormPage} />
      <Route exact path="/select-form/:id" component={CorvusSelectFormPage} />
      <Route exact path="/newform/:id/:relId" component={NewFormPage} />
      <Route exact path="/editable-list/:id" component={CorvusEditableList} />
      <Route
        exact
        path="/editable-list/:id/versions/:vRecordId"
        component={CorvusEditableList}
      />
      <Route
        exact
        path="/editable-list/:id/modal/:mRecordId"
        component={CorvusEditableList}
      />
      <Route exact path="/corvus-matrix" component={CorvusIframe} />
      {/* End Corvus Routes */}

      {/* Test with recharts */}
      <Route exact path="/chart/:id" component={ReCharts} />
      <Route exact path="/agChart/:id" component={AgreementCharts} />
      <Route exact path="/dashboard" component={DocumentCharts} />
      <Route exact path="/yearChart" component={YearChart} />
      <Route exact path="/dashboard/support" component={SupportCharts} />

      {/* Fields page under development */}
      <Route exact path="/form/:id" component={FbDynamicFormPage} />
      <Route exact path="/fields/:id" component={Fields} />
      <Route exact path="/formbuilder/:id" component={FormBuilderPage} />
      <Route exact path="/layout" component={FullWindow} />

      <Route exact path="/qlinea" component={QlineaDemoForm} />

      {/* Test custom component route */}
      <Route exact path="/test" component={DynamicEditableLinks} />

      <AgreementProvider>
        <Route
          exact
          path="/agreements"
          component={Agreements}
          // render={(props) => <Agreements {...props} />}
        />
        <Route exact path="/lista/:id" component={AgList} />
        <Route
          exact
          path="/agreements/subcategory/:id"
          // render={(props) => <Agreements {...props} />}
          component={Agreements}
        />
        <Route
          exact
          path="/agreements/filter/:filterColumn/:filterText"
          // render={(props) => <Agreements {...props} />}
          component={Agreements}
        />
        <Route
          exact
          path="/agreements/create-new-agreement"
          component={CreateAgreementsPage}
        />
        <Route
          exact
          path="/agreements/create-new-agreement/:cateId"
          component={CreateAgreementsPage}
        />
        <Route
          exact
          path="/agreements/create-new-agreement/:cateId/:nodId/:nodName"
          component={CreateAgreementsPage}
        />
        <Route
          exact
          path="/agreements/create-new-agreement/:nodId/:nodName"
          component={CreateAgreementsPage}
        />
        <Route
          exact
          path="/agreement/:id/:prop"
          render={(props) => <DynamicRoute {...props} />}
        />
        <Route
          exact
          path="/agreement/:id/:prop/:action"
          render={(props) => <DynamicRoute {...props} />}
        />
        <Route
          exact
          path="/agreement/:id"
          render={(props) => <DynamicRoute {...props} />}
        />
      </AgreementProvider>
      <Route exact path="/404" component={NoMatch} />
      {/* <Redirect exact path="*" to="/404" /> */}
    </>
  );
};

const Routes = () => {
  if (config.use_pwa_layout) {
    return (
      <UserProvider>
        <BrowserView>
          <MainRoutes />
        </BrowserView>
        <MobileView className="h-100">
          <MobileRoutes />
        </MobileView>
      </UserProvider>
    );
  }
  return (
    <UserProvider>
      <MainRoutes />
    </UserProvider>
  );
};

export default Routes;
