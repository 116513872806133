import axios, { AxiosRequestConfig } from "axios";

//MSAL token
import { loginRequest } from "../../azureConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

// OKTA token
import { useOktaAuth } from "@okta/okta-react";

import ConsoleLog from "./log/logErrror";
import { ApiURL } from "./checkUrl";

import config from "../../config";

const useApiHook = () => {
  const authState = useOktaAuth()?.authState;
  const { instance, accounts } = useMsal();

  const HEADERS_API = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const BASE_URL: any = ApiURL();

  const GET_API_HOOK = async (
    url: string,
    customHeaders?: object,
    axiosProps?: AxiosRequestConfig
  ) => {
    if (config.use_local_authentication) {
      let responseLocal: any;
      try {
        responseLocal = await axios({
          method: "GET",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: HEADERS_API,
          ...axiosProps,
        });
        return responseLocal;
      } catch (err) {
        responseLocal = err;
        return responseLocal;
      }
    } else if (config.use_okta_authentication) {
      let responseOkta: any;
      try {
        responseOkta = await axios({
          method: "GET",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: {
            ...HEADERS_API,
            ...customHeaders,
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
          ...axiosProps,
        });
        return responseOkta;
      } catch (err) {
        responseOkta = err;
        return responseOkta;
      }
    } else {
      let repsonseMSAL: any;
      repsonseMSAL = await instance
        .acquireTokenSilent({
          ...loginRequest,
          //@ts-ignore
          account: accounts[0],
        })
        .then(async (res) => {
          let repsonse: any;
          try {
            repsonse = await axios({
              method: "GET",
              withCredentials: true,
              url: `${BASE_URL}${url}`,
              headers: {
                ...HEADERS_API,
                ...customHeaders,
                Authorization: `Bearer ${res.accessToken}`,
              },
              ...axiosProps,
            });
            return repsonse;
          } catch (err) {
            repsonse = err;
            return repsonse;
          } finally {
            return repsonse;
          }
        })
        .catch(async (error) => {
          console.log({ error }, error instanceof InteractionRequiredAuthError);
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await instance
              .acquireTokenPopup(loginRequest)
              .then(async (response) => {
                console.log({ response });
                return await GET_API_HOOK(url, customHeaders && customHeaders);
              })
              .catch((error) => {
                ConsoleLog(error);
              });
          }
        });
      return repsonseMSAL;
    }
  };

  const POST_API_HOOK = async (
    url: string,
    body: object,
    customHeaders?: object,
    axiosProps?: AxiosRequestConfig
  ) => {
    if (config.use_local_authentication) {
      let responseLocal: any;
      try {
        responseLocal = await axios({
          method: "POST",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: HEADERS_API,
          data: body,
          ...axiosProps,
        });
        return responseLocal;
      } catch (err) {
        responseLocal = err;
        return responseLocal;
      }
    } else if (config.use_okta_authentication) {
      let responseOkta: any;
      try {
        responseOkta = await axios({
          method: "POST",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: {
            ...HEADERS_API,
            ...customHeaders,
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
          data: body,
          ...axiosProps,
        });
        return responseOkta;
      } catch (err) {
        responseOkta = err;
        return responseOkta;
      }
    } else {
      let repsonseMSAL: any;
      repsonseMSAL = instance
        .acquireTokenSilent({
          ...loginRequest,
          //@ts-ignore
          account: accounts[0],
        })
        .then(async (res) => {
          try {
            repsonseMSAL = await axios({
              method: "POST",
              withCredentials: true,
              url: `${BASE_URL}${url}`,
              headers: {
                ...HEADERS_API,
                ...customHeaders,
                Authorization: `Bearer ${res.accessToken}`,
              },
              data: body,
              ...axiosProps,
            });
            return repsonseMSAL;
          } catch (err) {
            repsonseMSAL = err;
            return repsonseMSAL;
          }
        })
        .catch(async (error) => {
          console.log({ error }, error instanceof InteractionRequiredAuthError);
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await instance
              .acquireTokenPopup(loginRequest)
              .then(async (response) => {
                console.log({ response });
                return await POST_API_HOOK(
                  url,
                  body,
                  customHeaders && customHeaders
                );
              })
              .catch((error) => {
                ConsoleLog(error);
              });
          }
        });
      return repsonseMSAL;
    }
  };

  const PUT_API_HOOK = async (
    url: string,
    body: object,
    customHeaders?: object,
    axiosProps?: AxiosRequestConfig
  ) => {
    if (config.use_local_authentication) {
      let responseLocal: any;
      try {
        responseLocal = await axios({
          method: "PUT",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: HEADERS_API,
          data: body,
          ...axiosProps,
        });
        return responseLocal;
      } catch (err) {
        responseLocal = err;
        return responseLocal;
      }
    } else if (config.use_okta_authentication) {
      let responseOkta: any;
      try {
        responseOkta = await axios({
          method: "PUT",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: {
            ...HEADERS_API,
            ...customHeaders,
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
          data: body,
          ...axiosProps,
        });
        return responseOkta;
      } catch (err) {
        responseOkta = err;
        return responseOkta;
      }
    } else {
      let repsonseMSAL: any;
      repsonseMSAL = instance
        .acquireTokenSilent({
          ...loginRequest,
          //@ts-ignore
          account: accounts[0],
        })
        .then(async (res) => {
          try {
            repsonseMSAL = await axios({
              method: "PUT",
              withCredentials: true,
              url: `${BASE_URL}${url}`,
              headers: {
                ...HEADERS_API,
                ...customHeaders,
                Authorization: `Bearer ${res.accessToken}`,
              },
              data: body,
              ...axiosProps,
            });
            return repsonseMSAL;
          } catch (err) {
            repsonseMSAL = err;
            return repsonseMSAL;
          }
        })
        .catch(async (error) => {
          console.log({ error }, error instanceof InteractionRequiredAuthError);
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await instance
              .acquireTokenPopup(loginRequest)
              .then(async (response) => {
                console.log({ response });
                return await PUT_API_HOOK(
                  url,
                  body,
                  customHeaders && customHeaders
                );
              })
              .catch((error) => {
                ConsoleLog(error);
              });
          }
        });
      return repsonseMSAL;
    }
  };

  const DELETE_API_HOOK = async (
    url: string,
    body?: object,
    customHeaders?: object,
    axiosProps?: AxiosRequestConfig
  ) => {
    if (config.use_local_authentication) {
      let responseLocal: any;
      try {
        responseLocal = await axios({
          method: "DELETE",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: HEADERS_API,
          data: body,
          ...axiosProps,
        });
        return responseLocal;
      } catch (err) {
        responseLocal = err;
        return responseLocal;
      }
    } else if (config.use_okta_authentication) {
      let responseOkta: any;
      try {
        responseOkta = await axios({
          method: "DELETE",
          withCredentials: true,
          url: `${BASE_URL}${url}`,
          headers: {
            ...HEADERS_API,
            ...customHeaders,
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
          data: body,
          ...axiosProps,
        });
        return responseOkta;
      } catch (err) {
        responseOkta = err;
        return responseOkta;
      }
    } else {
      let repsonseMSAL: any;
      repsonseMSAL = instance
        .acquireTokenSilent({
          ...loginRequest,
          //@ts-ignore
          account: accounts[0],
        })
        .then(async (res) => {
          try {
            repsonseMSAL = await axios({
              method: "DELETE",
              withCredentials: true,
              url: `${BASE_URL}${url}`,
              headers: {
                ...HEADERS_API,
                ...customHeaders,
                Authorization: `Bearer ${res.accessToken}`,
              },
              data: body,
              ...axiosProps,
            });
            return repsonseMSAL;
          } catch (err) {
            repsonseMSAL = err;
            return repsonseMSAL;
          }
        })
        .catch(async (error) => {
          console.log({ error }, error instanceof InteractionRequiredAuthError);
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await instance
              .acquireTokenPopup(loginRequest)
              .then(async (response) => {
                console.log({ response });
                return await DELETE_API_HOOK(
                  url,
                  body && body,
                  customHeaders && customHeaders
                );
              })
              .catch((error) => {
                ConsoleLog(error);
              });
          }
        });
      return repsonseMSAL;
    }
  };

  return {
    PUT_API_HOOK,
    POST_API_HOOK,
    DELETE_API_HOOK,
    GET_API_HOOK,
  };
};

export default useApiHook;
