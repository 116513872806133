import { FORM_Styling } from "./utils/typescript/types";

export const rmtMainColor = "#337ab7";
export const lightgreen = "#e5ffe5";
export const lightblue = "rgb(235, 248, 250)";
export const lightyellow = "#fffff3";
export const lightGray = "#dee2e6";
export const activestep = "#5bc0de";
export const disabledColor = "#e9ecef";
export const overlayBright = "rgba(255, 255, 255, 0.7)";
export const borderColor = "#ced4da";
export const errorBgColor = "mistyrose";
export const errorBorder = "1px solid #dc3545";
export const gridBorderColor = "#babfc7";

export const modalHeading =
  "border-bottom w-100 text-center pb-2 text-muted px-5";
export const toastHeading =
  "border-bottom w-100 text-center pb-1 text-muted mb-0";

export const formMaxWidth = "1200px";
export const labelMinHeight = "36px";
export const modalContentWidth = "500px";
export const labelTextColor = "#495057";

// Default form styling
export const formStyling: FORM_Styling = {
  formSection: {
    justifyContent: "center",
  },
  form: {
    backgroundColor: "#3379b710",
    border: "1px solid #337ab7",
    maxWidth: "1200px",
  },
  formInfo: {
    justifyContent: "center",
    alignItems: "start",
  },
  inputsPrimary: {
    backgroundColor: "#dceefd",
  },
  inpustSecondary: {
    backgroundColor: "#646464",
    border: "1px solid #6c757d",
  },
};
// Corvus custom colors
export const corvusFormStyling: FORM_Styling = {
  formSection: {
    justifyContent: "start",
  },
  form: {
    backgroundColor: "rgb(233, 250, 255)",
    border: "1px solid #17a2b8",
    maxWidth: "1500px",
  },
  formInfo: {
    justifyContent: "start",
    alignItems: "start",
  },
  inputsPrimary: {
    backgroundColor: "#ff9844",
    color: "black",
  },
  inpustSecondary: {
    backgroundColor: "#646464",
    border: "1px solid #6c757d",
  },
};

export const smallText = {
  fontSize: "0.7rem",
};

export const colorPrio = {
  low: "rgba(127, 255, 0, 0.3)",
  medium: "rgba(74, 207, 238, 0.3)",
  high: "rgba(255, 165, 0, 0.3)",
  critical: "rgba(255, 0, 0, 0.3)",
  unAssign: "white",
};

export const colorArray = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#3366cc",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];
