// LocalStorage functions

function SET_STORAGE(
  state: any,
  search: any,
  cardID: string,
  count: number,
  orderBy: { value: string; label: string } | null
) {
  const now = new Date();

  // time to last
  // 1 hour
  const ttl = 1000 * 60 * 60;

  const item = {
    search: search,
    cardID: cardID,
    count: count,
    filters: state,
    orderBy: orderBy,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem("az-search-filter", JSON.stringify(item));
}

function GET_STORAGE() {
  const FilterItems = localStorage.getItem("az-search-filter");
  if (!FilterItems) {
    return null;
  }

  const item = JSON.parse(FilterItems);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem("az-search-filter");
    return null;
  }
  return item;
}

function REMOVE_STORAGE() {
  localStorage.removeItem("az-search-filter");
}

// LocalStorage functions

function SET_LOCALSTORAGE(value: any, key: string, timeToLast?: number) {
  const now = new Date();

  // time to last
  // 45 minutes
  const ttl = timeToLast ? timeToLast : 1000 * 60 * 45;

  const item = {
    ...value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function GET_LOCALSTORAGE(key: string) {
  const items = localStorage.getItem(key);
  if (!items) {
    return null;
  }

  const item = JSON.parse(items);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item;
}

function REMOVE_LOCALSTORAGE(key: string) {
  localStorage.removeItem(key);
}

export {
  SET_STORAGE,
  GET_STORAGE,
  REMOVE_STORAGE,
  GET_LOCALSTORAGE,
  SET_LOCALSTORAGE,
  REMOVE_LOCALSTORAGE,
};
