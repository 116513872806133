const openModal = (modalRef: any) => {
  if (modalRef) {
    //@ts-ignore
    modalRef.current.openModal();
  }
};
const closeModal = (modalRef: any) => {
  if (modalRef) {
    modalRef.current && modalRef.current.close();
  }
};

const changeFullScreenState = (modalRef: any) => {
  if (modalRef) {
    //@ts-ignore
    modalRef.current.fullScreenModal();
  }
};

const showToast = (modalRef: any) => {
  if (modalRef) {
    //@ts-ignore
    modalRef.current.showToast();
  }
};
const hideToast = (modalRef: any) => {
  if (modalRef) {
    //@ts-ignore
    modalRef.current.hide();
  }
};

export { openModal, closeModal, changeFullScreenState, showToast, hideToast };
