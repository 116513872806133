import { lazy } from "react";
import { Route, Switch} from "react-router-dom";

const DynamicBlobStorageForm = lazy(
  () => import("../components/blobStorage/pages/BSFormPage")
);
const NoMatch = lazy(() => import("../components/noMatch/NoMatch"));

const BlobStorageRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={DynamicBlobStorageForm} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default BlobStorageRoutes;
