import { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "../src/components/utils/styles/burgermenu-style.scss"
import App from "./App";

import "./i18next/i18next";
import "../src/components/fontawesome";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <>
    <Suspense
      fallback={
        <div className="d-flex justify-content-center pt-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
    >
      <App />
      <ToastContainer />
    </Suspense>
  </>,
  document.getElementById("root")
);
