import React, { lazy, Suspense } from "react";
import "./App.scss";

import { HashRouter as Router, Switch } from "react-router-dom";

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { msalConfig } from "./azureConfig";
import { PublicClientApplication } from "@azure/msal-browser";

// import NoAuthNoMatch from "./components/noMatch/NoAuthNoMatch";
import { LoadingSpinner } from "./components/utils/layout/LoadingSpinner";

import Routes from "./routes/Routes";

import config from "./config";
import BlobStorageRoutes from "./routes/BlobStorageRoutes";
const OktaAppWithRouterAccess = lazy(
  () => import("./components/okta/OktaAppWithRouterAccess")
);

const LoginTemplate = lazy(() => import("./components/LoginTemplate"));

const MainContent = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AuthenticatedTemplate>
        <Router>
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginTemplate />
      </UnauthenticatedTemplate>
    </Suspense>
  );
};

const MsalAuthentication = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
    </MsalProvider>
  );
};

const OktaAuthentication = () => {
  return (
    <Router>
      <Switch>
        <OktaAppWithRouterAccess />
      </Switch>
    </Router>
  );
};

const App = () => {
  if (config.use_local_authentication) {
    return (
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    );
  } else if (config.use_blob_storage) {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
      <div className="App">
        <MsalProvider instance={msalInstance}>
          <Router>
            <BlobStorageRoutes />
          </Router>
        </MsalProvider>
      </div>
    );
  } else if (config.use_okta_authentication) {
    return (
      <>
      
        <div className="App">
          <OktaAuthentication />
        </div>
      </>
    );
  }
  return (
    <div className="App">
      <MsalAuthentication />
    </div>
  );
};

export default App;
